import * as React from 'react'
import NavHeader from '../../components/navHeader'
import SiteFooter from '../../components/footer'
import PageTitle from '../../components/pageTitle'
import { Link } from 'gatsby'
import * as eGovernmentStyle from '../../styles/eGovernment.module.scss'

const EGovernment = () => {
  return (
    <main>
    <NavHeader/>
    <PageTitle pageTitle="eGovernment"/>
        <div className="mainContainer">
        <div class="egovernment-container">
                <section className={eGovernmentStyle.egovSection}>
                    <div class="section-container">
                        <div className={eGovernmentStyle.articleHeading}>
                        <div><Link to="../../eGovernment"></Link></div>
                            <h1>Dotácie</h1>
                            
                        </div>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Východiská</h2>
                            <p>Približne 10-20% príjmov rozpočtu verejnej správy jednotlivé ministerstvá, úrady
                                a samosprávy
                                investujú prostredníctvom dotácií do rôznych rozvojových projektov. Na Slovensku
                                existuje
                                približne 3000 rôznych poskytovateľov dotácií, ktorí ich poskytujú na základe tisícok
                                rôznorodých dotačných schém. Údaje evidované o dotáciách boli heterogénne,
                                neelektronizované
                                (často len na papieri, alebo v Exceli) a nespoľahlivé.</p>
                            <p>Pre poskytovanie dotácii neexistoval jednotný systém ani jednotný proces. Štát nemal
                                prehľad
                                čo, koľko, komu a do čoho sa verejné prostriedky investujú. Pre občanov a podnikateľov
                                žiadajúcich o dotácie bol tento systém komplikovaný, neprehľadný a nepredvídateľný. </p>
                        </article>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Riešenie</h2>
                            <p>V rámci reformy verejnej správy je proces poskytovania dotácií postupne elektronizovaný.
                                Bol
                                vytvorený integrovaný IS, ktorý na jednom mieste obsahuje údaje o všetkých dotáciách
                                verejnej správy (v súčasnosti obsahuje ústredné orgány štátnej správy, samospráva je
                                v príprave). Jeho prostredníctvom je možné nielen získať online analytické údaje
                                o dotáciách, ale aj plne elektronicky zabezpečiť komplexný proces riadenia dotácií a to
                                z pohľadu žiadateľa o dotáciu (podanie žiadosti, vyúčtovanie dotácie) ako aj
                                poskytovateľa
                                (vyhlásenie výzvy na predkladanie projektov, hodnotenie, prevod prostriedkov, kontrola,
                                reporting). </p>
                        </article>
                        <article className={eGovernmentStyle.articleText}>
                            <h2>Výhody riešenia</h2>
                            <h3>Prijímatelia dotácií:</h3>
                            <ul>
                                <li>Komplexné informácie o všetkých dotáciách na jednom mieste</li>
                                <li>Pohodlný spôsob vykonania všetkých potrebných úkonov pre získanie dotácie v plne
                                    elektronickom režime</li>
                            </ul>
                            <h3>Poskytovatelia:</h3>
                            <ul>
                                <li>Pohodlný spôsob poskytovania dotácií v plne elektronickom alebo „papierovom“ režime
                                </li>
                                <li>Okamžitý komplexný prehľad o všetkých dotáciách
                                </li>
                                <li>Online analýzy dotácií za účelom lepšieho plánovania, vyššej efektivity a účinnosti
                                    poskytnutých dotácií na plnenie cieľov politík
                                </li>
                                <li>Rizikové analýzy a detekcie podvodov s dotáciami
                                </li>
                            </ul>
                        </article>
                    </div>
                </section>
            </div>
        </div>
    <SiteFooter/>
    </main>
  )
}


export default EGovernment